import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import SEO from "../components/Layout/SEO";
import BottomCTA from "../sections/camelo/BottomCTA";
import restaurantClosing from "../assets/image/templates/restaurant-closing-checklist.jpg";
import restaurantCleaning from "../assets/image/templates/restaurant-cleaning-checklist.jpeg";
import restaurantServer from "../assets/image/templates/restaurant-server-checklist.jpg";
import restaurantManager from "../assets/image/templates/restaurant-manager-job-description.jpg";
import barista from "../assets/image/templates/barista-job-description.jpg";
import foodRunner from "../assets/image/templates/food-runner-job-description.jpg";
import waiter from "../assets/image/templates/waiter-job-description.jpeg";
import interactiveTimeOff from "../assets/image/templates/interactive-time-off.jpeg";
import rotatingSchedule from "../assets/image/templates/rotating-schedule.jpeg";
import jobDescription from "../assets/image/templates/job-description.jpeg";
import workSchedule from "../assets/image/templates/work-schedule.jpeg";

const LegalPage = () => {
  return (
    <>
      <PageWrapper>
        <SEO
          title="Free Templates & Resources for Employee Scheduling & Time Tracking | Camelo"
          description="Free templates & resources for Excel, Google Sheets, PDF, and more
            to schedule staff, track hours worked & manage your team."
        ></SEO>
        <div className="inner-banner bg-default-1 pt-25 pt-lg-29">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="12" xl="12">
                <div className="px-md-15 text-center mb-5 mb-lg-13">
                  <h2 className="title gr-text-2 mb-9 mb-lg-12">
                    <span className="text-primary">Camelo</span> Templates
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-default-9 py-7 py-lg-14">
          <Container>
            <Row
              data-aos="fade-left"
              data-aos-duration="420"
              data-aos-easing="ease-in-out-quart"
              className="justify-content-center"
            >
              <Col md="6" lg="4" className="mt-10">
                <div className="customer-story-card mb-9 gr-hover-shadow-1 h-lg-100 bg-white rounded-10 overflow-hidden">
                  <Link
                    to="/templates/barista-job-description"
                    className="card-img"
                  >
                    <img className="w-100 rounded-top-10" src={barista} />
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/templates/barista-job-description">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        Barista Job Description Template
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link
                    to="/templates/food-runner-job-description"
                    className="card-img"
                  >
                    <img className="w-100 rounded-top-10" src={foodRunner} />
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/templates/food-runner-job-description">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        Food Runner Job Description Template
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link
                    to="/templates/restaurant-server-checklist"
                    className="card-img"
                  >
                    <img
                      className="w-100 rounded-top-10"
                      src={restaurantServer}
                    />
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/templates/restaurant-server-checklist">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        Restaurant Server Checklist Template
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-lg-100 bg-white rounded-10 overflow-hidden">
                  <Link
                    to="/templates/restaurant-cleaning-checklist"
                    className="card-img"
                  >
                    <img
                      className="w-100 rounded-top-10"
                      src={restaurantCleaning}
                    />
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/templates/restaurant-cleaning-checklist">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        Restaurant Cleaning Checklist Template
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link
                    to="/templates/restaurant-closing-checklist"
                    className="card-img"
                  >
                    <img
                      className="w-100 rounded-top-10"
                      src={restaurantClosing}
                    />
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/templates/restaurant-closing-checklist">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        Restaurant Closing Checklist Template
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link
                    to="/templates/restaurant-manager-job-description"
                    className="card-img"
                  >
                    <img
                      className="w-100 rounded-top-10"
                      src={restaurantManager}
                    />
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/templates/restaurant-manager-job-description">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        Restaurant Manager Job Description Template
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link
                    to="/templates/job-description-template"
                    className="card-img"
                  >
                    <img
                      className="w-100 rounded-top-10"
                      src={jobDescription}
                    />
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/templates/job-description-template">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        Job Description Template
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link
                    to="/templates/rotating-schedule-template"
                    className="card-img"
                  >
                    <img
                      className="w-100 rounded-top-10"
                      src={rotatingSchedule}
                    />
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/templates/rotating-schedule-template">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        Rotating Schedule Templates
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link
                    to="/templates/waiter-job-description"
                    className="card-img"
                  >
                    <img className="w-100 rounded-top-10" src={waiter} />
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/templates/waiter-job-description">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        Waiter Job Description Template
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link to="/templates/9-80-work-schedule" className="card-img">
                    <img className="w-100 rounded-top-10" src={workSchedule} />
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/templates/9-80-work-schedule">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        9/80 Work Schedule
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md="6" lg="4" className="mt-10">
                <div className="location-card mb-9 gr-hover-shadow-1 h-100 bg-white rounded-10 overflow-hidden">
                  <Link
                    to="/templates/interactive-time-off-request-form"
                    className="card-img"
                  >
                    <img
                      className="w-100 rounded-top-10"
                      src={interactiveTimeOff}
                    />
                  </Link>
                  <div className="card-content px-9 py-8 bg-white">
                    <Link to="/templates/interactive-time-off-request-form">
                      <h4 className="title text-blackish-blue gr-text-8 mb-0">
                        Interactive Time-Off Request Form
                      </h4>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="border-top">
          <BottomCTA />
        </div>
      </PageWrapper>
    </>
  );
};
export default LegalPage;
