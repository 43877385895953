import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

const CTA = () => {
  return (
    <>
      {/* <!-- CTA Area --> */}
      <div className="cta-section-1 py-12 pt-lg-20 pb-lg-18 border-bottom border-gray-3 ">
        <Container>
          <Row className="align-items-center ">
            <Col lg="7">
              <div className="section-title cta-text pr-lg-5">
                <h2 className="gr-text-5 mb-7">
                  Try now and see the difference yourself
                </h2>
                <p className="gr-text-8">
                  no credit card required, cancel anytime
                </p>
              </div>
            </Col>
            <Col lg="4" xl="4" className="offset-xl-1">
              <div className="cta-btn d-flex flex-column flex-sm-row justify-content-lg-end mt-5 mt-lg-0">
                <Link
                  onClick={() => {
                    if (typeof window.gtag_report_conversion === "function") {
                      window.gtag_report_conversion(
                        process.env.SIGNUP_URL,
                      );
                    }
                  }}
                  to={process.env.SIGNUP_URL}
                  target="__blank"
                  className="btn btn-primary with-icon gr-hover-y"
                >
                  Start scheduling free
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CTA;
